import React from 'react'
import Layout from '../components/layout'

function agb() {
    return (
        <Layout>
            <div className="main">
                <div className="global-text-container">
                    <h1>AGB</h1>
                        <h3>1. Anwendungs- und Geltungsbereich</h3>
                        <p>1.1 Die vorliegenden allgemeinen Gesch&auml;ftsbedingungen (nachfolgend &bdquo;AGB&ldquo;) finden Anwendung auf s&auml;mtliche Vertragsverh&auml;ltnisse zwischen by Olga Ribler GmbH und dessen by Olga Ribler Online Shop (nachfolgend &bdquo;by Olga Ribler&ldquo;) und ihren Kunden.</p>
                        <p>1.2 Der Terminus &bdquo;Kunde&ldquo; umfasst jede nat&uuml;rliche oder juristische Person, welche mit by Olga Ribler gesch&auml;ftliche Beziehungen pflegt.</p>
                        <p>1.3 Durch das Unterzeichnen der Auftragsbest&auml;tigung bescheinigt der Kunde, die AGB erhalten, gelesen und genehmigt zu haben. Der Kunde erkl&auml;rt sich vorbehaltlos einverstanden mit den AGB, welche damit zum integrierenden Vertragsbestandteil werden.</p>
                        <p>1.4 by Olga Ribler beh&auml;lt sich ausdr&uuml;cklich vor, die AGB jederzeit und ohne vorg&auml;ngige Information des Kunden zu &auml;ndern. Es gelten jeweils die im Zeitpunkt des Vertragsabschlusses auf der Website abrufbaren AGB.</p>
                        <h3>2. Schriftform</h3>
                        <p>2.1 Abweichungen von den AGB bed&uuml;rfen der Schriftform. Den vorliegenden AGB entgegenstehende AGB des Kunden sind nur g&uuml;ltig, sofern by Olga Ribler diesen ausdr&uuml;cklich schriftlich zugestimmt hat.</p>
                        <p>2.2 F&uuml;r die G&uuml;ltigkeit von &Auml;nderungen oder Erg&auml;nzungen von Vertr&auml;gen zwischen by Olga Ribler und dem Kunden bedarf es der Schriftlichkeit. Elektronische Mitteilungen sind der Schriftform gleichgestellt.</p>
                        <h3>3. Informationen auf der Website</h3>
                        <p>3.1 Der Online-Shop von by Olga Ribler beinhaltet Informationen &uuml;ber Produkte und Dienstleistungen. Preis- und Sortiments&auml;nderungen sowie technische &Auml;nderungen bleiben vorbehalten.</p>
                        <p>3.2 S&auml;mtliche Angaben (namentlich Produktbeschreibungen, Abbildungen, Filme, Masse, Gewichte, technische Spezifikationen, Zubeh&ouml;rbeziehungen etc.) sind als Richtwerte zu verstehen und stellen insbesondere keine Zusicherung von Eigenschaften oder Garantien dar. Selbstredend ist der Online-Shop von by Olga Ribler dennoch um die korrekte, vollst&auml;ndige, aktuelle und &uuml;bersichtliche Angabe von Informationen bem&uuml;ht.</p>
                        <h3>4. Offerte und Vertragsabschluss</h3>
                        <p>4.1 Die Angebote richten sich ausschliesslich nach der Verf&uuml;gbarkeit der entsprechenden Produkte und sind nicht als verbindliche Offerten zu verstehen.</p>
                        <p>4.2 by Olga Ribler &uuml;bernimmt keine Garantie f&uuml;r die effektive Verf&uuml;gbarkeit der zum Zeitpunkt der Bestellung im Online-Shop aufgef&uuml;hrten Produkte. S&auml;mtliche Angaben zu Verf&uuml;gbarkeit und Lieferfristen sind ohne Gew&auml;hr und k&ouml;nnen durch by Olga Ribler jederzeit ohne Ank&uuml;ndigung ge&auml;ndert werden.</p>
                        <p>4.3 Mit der Bestellung &uuml;ber diese Website sowie der Annahme der AGB geht der Kunde ein f&uuml;r ihn rechtlich verbindlicher Kauf ein, der mit der automatischen Bestellbest&auml;tigung best&auml;tigt wird.</p>
                        <p>4.4 Bestellte und verf&uuml;gbare Waren werden erst nach vollst&auml;ndigem Zahlungseingang ausgeliefert. Stellt sich nach Vertragsabschluss heraus, dass die bestellten Waren nicht oder nicht vollst&auml;ndig geliefert werden k&ouml;nnen, so ist die by Olga Ribler befugt, wahlweise und ohne vorg&auml;ngige Information des Kunden vom gesamten Vertrag oder von einem Vertragsbestandteil zur&uuml;ckzutreten. Bereits erhaltene Zahlungen werden zur&uuml;ckerstattet. Auf keinen Fall ist by Olga Ribler bei Vertragsaufl&ouml;sung infolge Nichtverf&uuml;gbarkeiten zu Ersatzlieferungen verpflichtet.</p>
                        <h3>5. Preise und Versandkosten</h3>
                        <p>5.1 S&auml;mtliche Preisangaben sind in Schweizer Franken und beinhalten die gesetzliche Mehrwertsteuer sowie allf&auml;llige vorgezogene Recyclinggeb&uuml;hren. Die angegebenen Preise beinhalten weder Beratungs- noch Supportdienstleistungen.</p>
                        <p>5.2 Technische &Auml;nderungen, Irrt&uuml;mer und Druckfehler bleiben ausdr&uuml;cklich vorbehalten. Preis&auml;nderungen k&ouml;nnen einseitig, jederzeit und ohne Vorank&uuml;ndigung vorgenommen werden.</p>
                        <p>5.3 Die Versandkosten werden zus&auml;tzlich in Rechnung gestellt und sind durch den Kunden zu bezahlen. Die Versandkosten werden im Bestellprozess in transparenter Weise ausgewiesen.</p>
                        <p>Der Versand erfolgt &uuml;ber &laquo;Die Post&raquo; f&uuml;r die Schweiz bzw. per FedEx f&uuml;r EU-L&auml;nder. (Sendungen verfolgbar)</p>
                        <p>Versandkosten:</p>
                        <p>SCHWEIZ -Inland-Paket Einschreiben 1-2 Werktage: CHF 8.50, ab 100.-Bestellungswert und bei den ausgef&uuml;hren Aktionen PORTOFREI<br />-EU-Paket FedEx Economy, 2-3 Werktage: EUR 30, dazu kommen die &uuml;bliche Zollgeb&uuml;hren EXTRA</p>
                        <h3>6. Zahlungsm&ouml;glichkeiten</h3>
                        <p>6.1 Dem Kunden stehen die im Bestellvorgang angegebenen Zahlungsm&ouml;glichkeiten zur Verf&uuml;gung (Bank&uuml;berweisung, Kreditkarte, PayPal, PostFinance). by Olga Ribler GmbH beh&auml;lt sich ausdr&uuml;cklich vor, Kunden ohne Angabe von Gr&uuml;nden von einzelnen Zahlungsm&ouml;glichkeiten auszuschliessen.</p>
                        <p>6.2 Nach Ablauf der auf der Rechnung ausgewiesenen Zahlungsfrist wird die gesamte in Rechnung gestellte Forderung f&auml;llig und es tritt ohne Mahnung der Verzug ein.</p>
                        <p>6.3 Bei Zahlungsverzug beh&auml;lt sich by Olga Ribler die Beauftragung eines Inkassounternehmens oder eines Anwaltes sowie die Abtretung der Forderung ausdr&uuml;cklich vor. Die anfallenden Kosten werden vom Kunden bezahlt.</p>
                        <p>6.4 by Olga Ribler ist berechtigt, Verzugszinsen von 7% sowie Mahngeb&uuml;hren von CHF 20.00 pro Mahnung zu erheben.</p>
                        <h3>7. Versand, Pr&uuml;fpflicht, M&auml;ngelr&uuml;ge und R&uuml;cksendung</h3>
                        <p>7.1 Die Lieferungen werden per Post oder Kurierdienst an die vom Kunden in der Bestellung angegebene Adresse versandt. Die Rechnungsstellung erfolgt per Email oder auf dem Postweg. Der &Uuml;bergang von Nutzen und Gefahr richtet sich nach der gesetzlichen Bestimmung von Art. 185 OR.</p>
                        <p>7.2 Ist die Lieferung nicht zustellbar oder verweigert der Kunde deren Annahme, kann by Olga Ribler den Vertrag unter Ansetzung einer angemessenen Nachfrist aufl&ouml;sen sowie die Kosten f&uuml;r den Schadenersatz in Rechnung stellen.</p>
                        <p>7.3 Der Kunde verpflichtet sich, die gelieferten Waren umgehend nach Erhalt zu pr&uuml;fen und allf&auml;llige M&auml;ngel by Olga Ribler unverz&uuml;glich anzuzeigen. Ist die Ware trotz geeigneter und gesch&uuml;tzter Verpackung besch&auml;digt, so ist dies innerhalb von 7 Tagen nach Erhalt bei der Post zu melden. Die Haftung der Post richtet sich nach den Bestimmungen des schweizerischen Obligationenrechtes &uuml;ber den Frachtvertrag. Die Post haftet bis zur H&ouml;he des nachgewiesenen Schadens, exkl. MWST. Siehe hierzu AGBs der Post.</p>
                        <p>7.4 Artikel, die Sie bei uns online eingekauft haben, k&ouml;nnen innerhalb von 14 Tagen in einem unserer Gesch&auml;fte gegen Quittung umgetauscht werden. R&uuml;cksendung per Post innerhalb von 14 Tagen. Porto tr&auml;gt der Kunde. by Olga Ribler GmbH &ndash; Weggisgasse 1 &ndash; CH-6004 Luzern</p>
                        <p><strong>Es wird nur original verpackter, nicht getragener Schmuck zur&uuml;ckgenommen. Sonderanfertigungen k&ouml;nnen nicht retourniert werden.</strong></p>
                        <p>7.5 Ergibt die Pr&uuml;fung durch by Olga Ribler, dass die Waren keine feststellbaren M&auml;ngel aufweisen oder diese nicht unter die Garantie des Herstellers fallen, werden s&auml;mtliche Kosten f&uuml;r die Umtriebe, die R&uuml;cksendung sowie die allf&auml;llige Entsorgung dem Kunden in Rechnung gestellt.</p>
                        <h3>8. Widerrufsrecht</h3>
                        <p>8.1 Dem Kunden wird w&auml;hrend 14 Kalendertagen nach Erhalt der Waren ein Widerrufsrecht gew&auml;hrt. Die Frist gilt als eingehalten, wenn der Kunde den schriftlichen Widerruf per Email (service@byolgaribler.com) oder Brief (by Olga Ribler GmbH, Weggisgasse 1., 6004 Luzern) innerhalb der Frist abschickt.</p>
                        <p>8.2 Die Aus&uuml;bung des Widerrufsrechts f&uuml;hrt zu einer R&uuml;ckabwicklung des Vertrages. Der Kunde hat die Waren innert 14 Tagen nach Erhalt originalverpackt, vollst&auml;ndig und zusammen mit dem Lieferschein an by Olga Ribler (by Olga Ribler GmbH, Weggisgasse 1., 6004 Luzern) zur&uuml;ckzusenden. S&auml;mtliche R&uuml;cksendungen erfolgen auf Rechnung und Gefahr des Kunden. Bereits geleistete Zahlungen werden innert 20 Kalendertagen ab Erhalt der Waren durch by Olga Ribler an den Kunden zur&uuml;ckerstattet. by Olga Ribler&nbsp; beh&auml;lt sich vor, durch Besch&auml;digungen, Abnutzung und dergleichen verursachte Wertverluste vom bereits bezahlten Kaufpreis in Abzug zu bringen oder dem Kunden gesondert in Rechnung zu stellen.</p>
                        <p>8.3 Das Widerrufsrecht ist insbesondere ausgeschlossen:</p>
                        <p>(i) Wenn der Vertrag ein Zufallselement hat, namentlich wenn der Preis Schwankungen unterliegt, auf die der Anbieter keinen Einfluss hat.</p>
                        <p>(ii) Wenn der Vertrag eine bewegliche Sache zum Gegenstand hat, die aufgrund ihrer Beschaffenheit nicht f&uuml;r eine R&uuml;cksendung geeignet ist oder schnell verderben kann.</p>
                        <p>(iii) Wenn der Vertrag eine bewegliche Sache zum Gegenstand hat, die nach Vorgaben des Konsumenten angefertigt wurde oder eindeutig auf pers&ouml;nliche Bed&uuml;rfnisse zugeschnitten ist.</p>
                        <p>(iv) Wenn der Vertrag eine Dienstleistung zum Gegenstand hat, welche bereits vor dem Ablauf der Widerrufsfrist erbracht wurde oder sich der Anbieter bei Vertragsabschluss verpflichtete, die Dienstleistung zu einem bestimmten Zeitpunkt oder innerhalb eines genau angegebenen Zeitraums zu erbringen.</p>
                        <h3>9. Haftung</h3>
                        <p>9.1 Unabh&auml;ngig des Rechtsgrundes schliesst by Olga Ribler jede Haftung f&uuml;r von ihr sowie ihren Hilfspersonen und Erf&uuml;llungsgehilfen verursachten Sch&auml;den aus. Die Wegbedingung umfasst auch grobe Fahrl&auml;ssigkeit.</p>
                        <p>9.2 by Olga Ribler haftet insbesondere nicht f&uuml;r indirekte Sch&auml;den, Mangelfolgesch&auml;den, entgangenen Gewinn oder sonstige Personen-, Sach- und reine Verm&ouml;genssch&auml;den des Kunden.</p>
                        <p>9.3 by Olga Ribler benutzt Hyperlinks lediglich f&uuml;r den vereinfachten Zugang des Kunden zu anderen Webangeboten. by Olga Ribler kann weder den Inhalt dieser Webangebote im Einzelnen kennen noch die Haftung oder sonstige Verantwortung f&uuml;r die Inhalte dieser Website &uuml;bernehmen.</p>
                        <h3>10. Gew&auml;hrleistung</h3>
                        <p>10.1 by Olga Ribler liefert grunds&auml;tzlich Ware in einwandfreier Qualit&auml;t. Bei rechtzeitig ger&uuml;gten M&auml;ngeln &uuml;bernimmt by Olga Ribler w&auml;hrend der gesetzlichen Gew&auml;hrleistungsfrist die Gew&auml;hrleistung f&uuml;r M&auml;ngelfreiheit des vom Kunden erworbenen Gegenstandes. Es liegt im Ermessen von by Olga Ribler, die Gew&auml;hrleistung durch kostenlose Reparatur, gleichwertigen Ersatz oder durch R&uuml;ckerstattung des Kaufpreises zu erbringen. Weitere Anspr&uuml;che sind ausgeschlossen.</p>
                        <p>10.2 Von der Gew&auml;hrleistung sind die normale Abn&uuml;tzung sowie die Folgen unsachgem&auml;sser Behandlung oder Besch&auml;digung durch den Kunden oder Drittpersonen sowie M&auml;ngel, welche auf &auml;ussere Umst&auml;nde und h&ouml;here Gewalt zur&uuml;ckzuf&uuml;hren sind, nicht erfasst. Ebenso wird die Gew&auml;hrleistung f&uuml;r Verbrauchs- und Verschleissteile wegbedungen.</p>
                        <p>10.3 by Olga Ribler gibt keine Zusicherungen oder Garantien f&uuml;r die Aktualit&auml;t, die Vollst&auml;ndigkeit oder die Korrektheit der Daten sowie f&uuml;r die st&auml;ndige und ungest&ouml;rte Verf&uuml;gbarkeit der Website, deren Funktionalit&auml;ten, integrierten Hyperlinks und weiteren Inhalten abzugeben. Insbesondere wird weder zugesichert noch garantiert, dass durch die Nutzung der Website keine Rechte von Dritten verletzt werden, die nicht im Eigentum von by Olga Ribler stehen.</p>
                        <h3>11. Datenschutz</h3>
                        <p>11.1 by Olga Ribler darf die im Rahmen des Vertragsschlusses aufgenommenen Daten zur Erf&uuml;llung der Verpflichtungen aus dem Kaufvertrag verarbeiten, nutzen sowie zu Marketingzwecken verwenden. Die zur Leistungserf&uuml;llung notwendigen Daten k&ouml;nnen an beauftragte Dienstleistungspartner (z.B. Logistikparter) oder sonstige Dritte weitergegeben werden.</p>
                        <p>11.2 Die weiteren Datenschutzbestimmungen sind unter folgendem Link abrufbar:&nbsp;<a href="https://www.byolgaribler.com/datenschutz/">https://www.byolgaribler.com/datenschutz/</a></p>
                        <h3>12. Rechtshinweis</h3>
                        <p>Der Inhalt der Website ist urheberrechtlich gesch&uuml;tzt. Ihr Inhalt darf nur mit schriftlicher Zustimmung von by Olga Ribler verbreitet, ver&ouml;ffentlicht oder weiter verwendet werden, sei es auch in abge&auml;nderter Form.</p>
                        <h3>13. Anwendbares Recht und Gerichtsstand</h3>
                        <p>13.1 Die allgemeinen Gesch&auml;ftsbedingungen sowie s&auml;mtliche vertragliche Vereinbarungen von by Olga Ribler unterstehen dem schweizerischen Recht, unter Ausschluss der Kollisionsnormen des internationalen Privatrechts sowie des Wiener &Uuml;bereinkommens &uuml;ber den internationalen Warenverkauf.</p>
                        <p>13.2 Ungeachtet des Rechtsgrundes ist ausschliesslicher Gerichtsstand f&uuml;r s&auml;mtliche Rechtsstreitigkeiten Luzern.</p>
                        <h3>14. Salvatorische Klausel</h3>
                        <p>Sollten einzelne oder mehrere Bestimmungen der AGB ganz oder teilweise unwirksam resp. nichtig sein oder werden, so wird die Wirksamkeit der AGB im &Uuml;brigen nicht ber&uuml;hrt.</p>
                        <p>Luzern, 1.6.2016</p>
                </div>
            </div>
        </Layout>
    )
}

export default agb
